import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';

import { RootState, Region, Location } from '@/store/types';
import {
  JobsWithCriterias,
  JobsFilterParams,
  JobsRouteParams,
  JobsDestinationParams,
} from '@/api/types';

/* eslint-disable-next-line -- typesItem  */
import DropdownSelectItem from '@/components/controls/types/DropdownSelectItem';
import { QuestionSet } from '@/utils/job-app-questions';

// Store
export interface State {
  categories: Category[];
  activeCategories: Category[];
  activeSubcategories: number[];
  activeExperiences: Experience[];
  activeIndustries: Industry[];
  activeCompanySizes: DropdownSelectItem[];
  activeRegions: Region[];
  activeRegionsSorting: number[];
  activeLocations: Location[];
  activeSorting: string;
  basicCompaniesJobs: {
    paginationCount: number;
    companyCount: number;
    companiesJobs: CompanyJobs[];
    jobsCount: number;
    jobAllCount: number;
    searchWeightId: string;
  };
  eliteJobs: {
    highVolumeJobs: HVJobs;
    jobs: Job[];
    job_count: number;
    job_all_count: number;
    searchWeightId: string;
  };
  jobsWithCompaniesRequests: string[];
  jobsWithCompaniesResponds: string[];
  filteredJobs: Job[];
  subcategories: SubcategoryRelation;
  eliteCompanies: JobCompany[];
  loadMoreCompany: LoadMoreCompanyRelation;
  experiences: Experience[];
  industries: Industry[];
  nationwide: boolean;
  page: number;
  search: string;
  remoteStatus: number[];
  selectedFilters: selectedFilters;
  currentRoutePath: string;
}
export interface selectedFilters {
  categories: Category[];
  subCategories: Subcategory[];
  experiences: Experience[];
  industries: Industry[];
  companySize: DropdownSelectItem[];
  regions: Region[];
  locations: Location[];
  remoteStatus: number[];
  nationwide: Boolean;
  page: number;
  search?: string | undefined;
}
export interface selectedFiltersPayload {
  categories?: Category[];
  subCategories?: Subcategory[];
  experiences?: Experience[];
  industries?: Industry[];
  companySize?: DropdownSelectItem[];
  regions?: Region[];
  locations?: Location[];
  remoteStatus?: number[];
  nationwide?: Boolean;
  page?: number;
  search?: string;
}

export const namespace = 'jobs';

export interface JobsGetters extends GetterTree<State, RootState> {
  perPage: (state: State) => number;
  searchWeightId: (state: State) => string;
  getNationwide: (state: State) => boolean;
  ownLocationSelected: (state: State) => boolean;
  hasSelectedFilters: (state: State, getters: any) => boolean;
  routeParams: (state: State, getters: any) => JobsRouteParams;
  buildRoute: (
    state: State,
    getters: any,
    rootState: RootState
  ) => (params: JobsRouteParams, destinationParams: JobsDestinationParams) => string;
  filteredJobs: (state: State) => Job[];
  listedCompany: (state: State) => (companyId: number, elite: boolean) => JobCompany | undefined;
  metaTagsInfo: (state: State, getters: any) => MetaTagsInfo;
  filteringParams: (state: State, getters: any) => JobsWithCriterias;
  jobsPathPreFiltered: (state: State, getters: any, rootState: any) => (remote: boolean) => string;
  activeRegions: (state: State) => Region[];
  activeLocationNames: (state: State) => string[];
  targetJobsLocations: (state: State) => string[];
  eliteJobsData: (state: State) => {
    highVolumeJobs: HVJobs;
    jobs: Job[];
    job_count: number;
    job_all_count: number;
    searchWeightId: string;
  };
  jobCount: (state: State) => number;
  isRemoteSelected: (state: State) => boolean;
  isHybridSelected: (state: State) => boolean;
  isInOfficeSelected: (state: State) => boolean;
}

export enum JobMutationAlias {
  setCategories = 'setCategories',
  setIndustries = 'setIndustries',
  setSubcategories = 'setSubcategories',
  activateCategory = 'activateCategory',
  deactivateCategory = 'deactivateCategory',
}

export interface JobsMutations extends MutationTree<State> {
  activateCategory: (state: State, category: Category) => void;
  deactivateCategory: (state: State, category: Category) => void;
  activateSubcategory: (state: State, subcategoryId: number) => void;
  deactivateSubcategoriesForCategory: (state: State, category: any) => void;
  deactivateExperience: (state: State, payload?: Experience) => void;
  activateExperience: (state: State, payload: Experience) => void;
  activateIndustry: (state: State, payload: Industry) => void;
  deactivateIndustry: (state: State, payload?: FacetItem) => void;
  activateRegionSingle: (state: State, region: Region) => void;
  setRemoteStatus: (state: State, status: number[]) => void;
  setPage: (state: State, page: number) => void;
  setSearchKeyword: (state: State, keyword: string) => void;
  setActiveSorting: (state: State, name: string) => void;
  _updateFiltersData: (state: State, data: FiltersDataState) => void;
}

export interface JobsActions extends ActionTree<State, RootState> {
  getJobsWithCompanies: (
    ctx: ActionContext<State, RootState>,
    payload?: JobsWithCriterias
  ) => Promise<void>;
  getFilteredJobs: (
    ctx: ActionContext<State, RootState>,
    payload: JobsFilterParams
  ) => Promise<void>;
  getCategories: (ctx: ActionContext<State, RootState>) => any;
  getIndustries: (ctx: ActionContext<State, RootState>) => any;
  getSubcategoryData: (ctx: ActionContext<State, RootState>) => any;
  loadMoreCompanyJobs: (
    ctx: ActionContext<State, RootState>,
    payload: LoadCompanyJobs
  ) => Promise<boolean>;
  filteringParamsInitData: (ctx: ActionContext<State, RootState>, data: FiltersData) => void;

  setSelectedFilters: (
    ctx: ActionContext<State, RootState>,
    selectedFilters: selectedFiltersPayload
  ) => any;
}

// Facet Experience list
export const ExperiencesList: Experience[] = [
  { id: 1, values: ['1'], label: 'Entry Level', alias: 'entry-level' },
  { id: 2, values: ['1-3', '3-5', '3', '5'], label: 'Mid Level', alias: 'mid-level' },
  { id: 3, values: ['5-7', '7+', '7'], label: 'Senior Level', alias: 'senior' },
  { id: 4, values: ['0'], label: 'Not Specified', alias: 'not-specified' },
];

export const removedSubLocations: Set<string> = new Set([
  'chicago-south-suburbs',
  'chicago-west-suburbs',
  'austin-suburbs',
  'western-colorado',
  'greater-denver-area',
  'greater-boulder-area',
  'brookline',
  'greater-boston-area',
  'lexington',
  'newton',
  'watertown',
  'hollywood',
  'greater-la-area',
  'redmond',
  'north-bay',
]);

export const RemoteOnly: 'REMOTE_ENABLED' = 'REMOTE_ENABLED';
export const RemoteOff: 'NOT_REMOTE' = 'NOT_REMOTE';

export interface HVJobsData {
  hv_job_ids: number[];
  hv_jobs: Job[];
}

export interface HVJobs {
  [companyId: number]: HVJobsData;
}
export interface SubcategoryRelation {
  [cat_id: number]: Subcategory[];
}

// SubcategoryMap
export interface SubcategoryMap {
  subcategories: Subcategory[];
}

// JobsData
export interface JobsData {
  jobs: Job[];
  job_count: number;
  searchWeightId: string;
}

// JobsCompaniesData
export interface JobsCompaniesData {
  company_count: number;
  jobs: Job[];
  high_volume_jobs: HVJobs;
  job_count: number;
  job_all_count: number;
  companies: JobCompany[];
  load_more_company: LoadMoreCompany;
  search_weight_id: string;
}

export interface JobsFromAPIData {
  items: JobDto[];
  totalItems: number;
}

export interface JobsLocationData {
  longitude: string;
  latitude: string;
  label: string;
}

export interface JobsCompaniesCollapsedResponse {
  company_count: number; // Total number of pages to represent in the pagination.
  pagination_count: number;
  company_jobs: CompanyJobsItemResponse[];
  high_volume_jobs: [];
  industries: []; // Not used by frontend.
  job_all_count: number; // Total number of all jobs from ES.
  job_count: number; // Total number of all jobs from ES.
  search_weight_id: string;
}

export interface CompanyJobsItemResponse {
  company: JobCompany;
  jobs: Job[];
  job_total: number;
}

export interface CompanyJobs {
  company: JobCompany;
  jobs: Job[];
  jobTotal: number;
}

// Category
export interface Category {
  id: number;
  type_id: number;
  name: string;
  seo_name: string;
  alias: string;
}

// Subcategory
export interface Subcategory {
  id: number;
  attr_id: number;
  name: string;
  seo_name: string;
  alias: string;
}

// Job
export interface Job {
  id: number;
  title: string;
  created_at: string;
  autorenew: boolean;
  company_id: number;
  category_id: number;
  companyId: number;
  companyUrl: string;
  companyName: string;
  companyLogo: string;
  location: string;
  original_location?: string;
  location_id?: number; // for now JRS don't return location_id
  purchased_with_credits: number;
  renewed_times: number;
  expires: string | null;
  experience_level: string;
  how_to_apply: string;
  import_job: string;
  body: string;
  body_summary: string;
  ats_details: JobATSDetails;
  sort_job: string | null;
  meta_tags: object;
  unpublished_date: string | null;
  published_date: string | null;
  job_sub_categories: object;
  alias: string;
  url: string;
  remote_status: string;
  hot_jobs_score: number;
  region_id: number;
  geo_locations?: GeoLocation[];
  targeted_remote_locations?: TargetedRemoteLocation[];
  easy_apply: boolean;
  hybrid: boolean;
  eeoc: boolean;
}

interface IndustryDto {
  id: number;
  name: string;
  alias: string;
  seoName: string;
}

// data interface from new api
export interface JobDto {
  id: number;
  title: string;
  alias: string;
  url: string;
  companyId: number;
  companyName: string;
  companyLogo: string;
  companyAlias: string;
  companyUrl: string;
  companySize: number;
  companyPerkIds: number[];
  body: string;
  location?: string;
  geoLocations: GeoLocation[];
  lastUpdated?: Date;
  experience: string;
  industries: IndustryDto[];
  isRemote: boolean;
  isHybrid: boolean;
  isOnSite: boolean;
  isEasyApply: boolean;
  salaryMin?: number;
  salaryMax?: number;
  salarySingle?: number;
  salaryType?: string;
  regions: number[];
  locations: number[];
  regionId?: number;
  howToApply?: string;
  // @todo not implemented, add  categoryId to jobsApi
  categoryId?: number;
  // @todo not implemented, add bodySummary to jobsApi
  bodySummary?: string;
}

export interface JobATSDetails {
  job_id: string;
}

export interface JobCountByLocation {
  location_id: number;
  count: number;
}
export interface Attr {
  id: number;
  type_id: number;
  name: string;
}
export interface Perk {
  id?: number;
  company_id?: number;
  perk_id?: number;
  perk_weight?: number;
  perkID?: number;
  name?: string;
  description?: string;
  [index: string]: any;
}
export interface JobCompany {
  id: number;
  region_id: number;
  title: string;
  high_volume_poster: boolean;
  insider_page: boolean;
  subscription_id: number;
  job_slots: number;
  logo: string;
  company_perks: Perk[];
  limited_listing?: boolean | null;
  alias?: string;
}

export interface LoadMoreCompany {
  id: number;
  title: string;
  high_volume_poster: boolean;
  job_count: number;
}

export interface LoadMoreCompanyRelation {
  [job_id: number]: LoadMoreCompany;
}

export interface Experience {
  id: number;
  values: string[];
  label: string;
  alias: string;
}

export interface Industry {
  id: number;
  name: string;
  seo_name: string;
  alias: string;
}

/** @deprecated Prefer FacetDropDownItem or DropdownSelectItem interface */
export interface FacetItem {
  id: number;
  title: string;
  path: string;
  active: boolean;
  alias: string;
  count?: number;
  items?: FacetItem[];
}

export interface MetaTagsInfo {
  title: string;
  h1: string;
  description: string;
  locationNames: string[];
  regionNames: string[];
  remoteStatus: number[];
  categories: any;
  isRemoteSelected: boolean;
  isHybridSelected: boolean;
  isInOfficeSelected: boolean;
}

export interface GeoLocation {
  state: string;
  city: string;
  coordinates: string;
  geoName: string;
}

export interface TargetedRemoteLocation {
  location_id: number;
  region_id: number;
}

export interface LoadCompanyJobs {
  companyId: number;
}

export interface FiltersData {
  categories: Category[];
  subCategories: Subcategory[];
  experiences: Experience[];
  industries: Industry[];
  companySize: DropdownSelectItem[];
  regions: Region[];
  locations: Location[];
  remoteStatus: number[];
  page: number;
  search: string;
  sorting: string;
  isNationwideUrlPath: boolean;
}

export interface FiltersDataState {
  categories: Category[];
  subCategories: number[];
  experiences: Experience[];
  industries: Industry[];
  companySize: DropdownSelectItem[];
  regions: Region[];
  locations: Location[];
  remoteStatus: number[];
  page: number;
  search: string;
  sorting: string;
}
export interface DataCompliance {
  RequiresConsent: boolean;
  RequiresProcessingConsent: boolean;
  RequiresRetentionConsent: boolean;
  RetentionPeriod: number;
}

export interface EasyApplyJob {
  Job: Job;
  Questions: QuestionSet[];
  DataCompliance: DataCompliance;
}
